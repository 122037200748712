import { useAnimation } from "@angular/animations";
import { AppComponent } from "../app/app.component";

import { AppConfig } from "../app/app.config"

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {

  production: true,
  brandName: "Groupbenefitz",
  app: {
    // url: "https://api-staging-saus.internal.kii.com/api/apps/isal37r1nd3r/server-code/versions/current",
    // murl: "https://api-staging-saus.internal.kii.com/api/apps/isal37r1nd3r/",

    // bfcurl:"http://admintestws.groupBenfitz.com/api/",

//Test Enivornment
    // grp: "https://testapi.groupbenefitz.aitestpro.com/",
    // globalgrp:"https://test.groupbenefitz.aitestpro.com/",
    // logourl:'https://testadminapi.groupbenefitz.aitestpro.com/api/ap/',
    // commonurl:'https://testcommonapi.groupbenefitz.aitestpro.com/api/ap/',
    // waddellink:'https://test.groupbenefitz.aitestpro.com/waddell/',

//Sandbox Environment
    // grp:"https://api-dev.groupbenefitz.ca/",
    // globalgrp:"https://sandbox.groupbenefitz.ca/",
    // logourl:'https://api-admin.groupbenefitz.org/api/ap/',
    //commonurl:'https://api-common.groupbenefitz.org/api/ap/',

//Production Environment
    grp:"https://api.groupbenefitz.ca/",
    globalgrp:"https://signup.groupbenefitz.ca/",
    logourl:'https://api-admin.groupbenefitz.net/api/ap/',
    commonurl:'https://api-common.groupbenefitz.org/api/ap/',
    waddellink:'https://signup.groupbenefitz.ca/waddell/',


//igbms Enviornmet    
// grp: "https://api.igbms.com/",
// globalgrp:"https://signup.igbms.com/",
// logourl:'https://adminapi.igbms.com/api/ap/',
// commonurl:'https://api-common.groupbenefitz.org/api/ap/',
// waddellink:'https://signup.igbms.com/waddell/8K2EHkaW1470',


    personalInfoCaptcha:"6Lfj88QgAAAAAAJ8bY1NW0Bgmm9V8PV2onC4RCNx",
    fusbillrecaptcha:"6LfVtGwUAAAAALHn9Ycaig9801f6lrPmouzuKF11",

    localgrp:"http://localhost:4200/",
    atp: "https://dev.aitestpro.com/",



    id: "fooClientId",
    key: "secret",
  },


  googleMapKey: "AIzaSyCx_ZaqWDu6leZ7ffeIz5sG9qrN5s4KFF0",
  debugEnabled: true,
};
